// ValidationRules always consist of a function and an error-message in the form `function || 'error-message'`.
// If the function is returns false, then the error-message is returned.

const isFilled = value => value.length > 0 || 'Bitte fülle dieses Feld aus.';

const isNamePattern = value =>
  value.length === 0 ||
  /^[^0-9@§$%&/()=?¡¶¢\[\]|{}≠+*;]+$/.test(value) ||
  'Ungültige Sonderzeichen oder Zahlen in der Eingabe.';

const isAtInAddress = value => /@/.test(value) || `Bitte füge ein "@" in die E-Mail-Adresse ein.`;

const isValidEmailAddress = value =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  ) || 'Bitte gib eine valide E-Mail-Adresse ein.';

const isSameEmailValue = (value, defaultValue) =>
  value !== defaultValue || 'Deine Eingabe ist identisch zur bestehenden Adresse.';

export {isFilled, isAtInAddress, isValidEmailAddress, isSameEmailValue, isNamePattern};
